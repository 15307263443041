import { defineComponent, useContext, computed, ref, watch, onMounted, } from '@nuxtjs/composition-api';
import { ZnButton, ZnTextButton } from '~/modules/b2b/components';
import { onSSR } from '@vue-storefront/core';
import { useContentful, useCart, useMagentoConfiguration, } from '~/composables';
import { CartDetail, OrderSummary } from '~/modules/b2b/checkout/components';
import { useCheckout, useBraintree } from '~/modules/b2b/checkout/composables';
import LogoBar from '~/modules/b2b/components/beast/LogoBar.vue';
import { useB2BUiState } from '~/modules/b2b/composables';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import { useUrlLanguageParser } from '~/modules/b2b/localization';
export default defineComponent({
    name: 'Cart',
    components: {
        ZnButton,
        CartDetail,
        OrderSummary,
        ZnTextButton,
        LogoBar,
        SkeletonLoader,
    },
    // middleware: ['avoid-empty-with-items'],
    setup(_, { emit }) {
        const { search, content: cartPage } = useContentful('cartPage');
        const { cart, loading: cartLoading } = useCart();
        const loading = ref(false);
        const { goTo } = useB2BUiState();
        const { $config } = useContext();
        const { defaultMagentoCategory } = $config;
        const { cartTotalsLoading, shippingOptions, selectedShippingMethod, getShippingOptionsWithMockData, } = useCheckout();
        const { isRegion } = useMagentoConfiguration();
        const products = computed(() => { var _a; return ((_a = cart.value) === null || _a === void 0 ? void 0 : _a.items) || []; });
        const { store } = useUrlLanguageParser();
        const { getClient, createPaypalCheckoutInstance, createApplePayInstance } = useBraintree({ emit });
        onSSR(async () => {
            await search({ url: 'cart-page' });
        });
        onMounted(async () => {
            // preload braintree client
            await getClient();
            createPaypalCheckoutInstance();
            createApplePayInstance();
        });
        const prevRoute = computed(() => { var _a, _b; return ((_b = (_a = cartPage.value[0]) === null || _a === void 0 ? void 0 : _a.fields) === null || _b === void 0 ? void 0 : _b.prevRoute) || defaultMagentoCategory; });
        const prevText = computed(() => { var _a, _b; return ((_b = (_a = cartPage.value[0]) === null || _a === void 0 ? void 0 : _a.fields) === null || _b === void 0 ? void 0 : _b.prevText) || 'Product page'; });
        const nextRoute = computed(() => { var _a, _b; return ((_b = (_a = cartPage.value[0]) === null || _a === void 0 ? void 0 : _a.fields) === null || _b === void 0 ? void 0 : _b.nextRoute) || defaultMagentoCategory; });
        const nextText = computed(() => { var _a, _b; return ((_b = (_a = cartPage.value[0]) === null || _a === void 0 ? void 0 : _a.fields) === null || _b === void 0 ? void 0 : _b.nextText) || 'Go to shopping'; });
        const pageTitle = computed(() => { var _a, _b; return ((_b = (_a = cartPage.value[0]) === null || _a === void 0 ? void 0 : _a.fields) === null || _b === void 0 ? void 0 : _b.title) || 'Cart Page'; });
        const summaryData = computed(() => {
            var _a, _b, _c;
            return ({
                subTotal: ((_c = (_b = (_a = cart.value) === null || _a === void 0 ? void 0 : _a.prices) === null || _b === void 0 ? void 0 : _b.subtotal_excluding_tax) === null || _c === void 0 ? void 0 : _c.value) || 0,
            });
        });
        const localLoading = computed(() => loading.value || cartLoading.value);
        const totalItems = computed(() => { var _a; return ((_a = cart.value) === null || _a === void 0 ? void 0 : _a.total_quantity) || 0; });
        watch(() => cartLoading.value, () => {
            var _a, _b;
            if (!Number.isNaN((_a = cart.value) === null || _a === void 0 ? void 0 : _a.total_quantity)
                && ((_b = cart.value) === null || _b === void 0 ? void 0 : _b.total_quantity) > 0
                && !isRegion.value) {
                getShippingOptionsWithMockData();
            }
        }, { immediate: true, deep: true });
        const urlRewriteRedirect = computed(() => {
            var _a, _b, _c, _d;
            const urlRewrite = (_d = (_c = (_b = (_a = products.value) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.product) === null || _c === void 0 ? void 0 : _c.url_rewrites) === null || _d === void 0 ? void 0 : _d.filter((rewrite) => { var _a; return ((_a = rewrite.url.match(/\//g)) === null || _a === void 0 ? void 0 : _a.length) === 2; });
            return (urlRewrite === null || urlRewrite === void 0 ? void 0 : urlRewrite.length) > 0 ? `/${urlRewrite[0].url}` : undefined;
        });
        const goToNext = () => {
            goTo({ path: nextRoute.value });
        };
        const goToPrev = () => {
            goTo({ path: urlRewriteRedirect.value || prevRoute.value || '/quest/products/quest-3' });
        };
        const goToShopping = () => {
            goTo({ path: urlRewriteRedirect.value || '/' });
        };
        return {
            loading,
            products,
            totalItems,
            cartPage,
            pageTitle,
            prevText,
            nextText,
            summaryData,
            localLoading,
            cartTotalsLoading,
            goToPrev,
            goToNext,
            goToShopping,
            shippingOptions,
            selectedShippingMethod,
            store,
        };
    },
    head() {
        return {
            title: this.pageTitle,
        };
    },
});
